// Based on https://material.google.com/components/buttons.html AND https://material-density.glitch.me/

$btn-bg-active:               rgba(153, 153, 153, 0.4) !default;
$btn-bg-active-inverse:       rgba(204, 204, 204, 0.25) !default;
$btn-bg-disabled:             $black-divider !default;
$btn-bg-disabled-inverse:     $white-divider !default;
$btn-border-radius:           $border-radius !default;
$btn-block-margin-y:          $spacer-xs !default;
$btn-color:                   $black-primary !default;
$btn-color-disabled:          rgba($black, 0.37) !default;
$btn-color-disabled-inverse:  rgba($white, 0.3) !default;
$btn-elevation-shadow:        map-get($elevation-shadows, 2) !default;
$btn-elevation-shadow-hover:  map-get($elevation-shadows, 4) !default;
$btn-elevation-shadow-active: map-get($elevation-shadows, 8) !default;
$btn-font-weight:             $font-weight-medium !default;
$btn-icon-size:               1.125rem !default; // Icon within a raised/outlined/text button
//$btn-line-height:             1 !default;
$btn-min-width:               4rem !default; // 64px
$btn-overlay:                 $black-divider !default;
$btn-overlay-inverse:         rgba($white, 0.08) !default; //$white-divider

$btn-font-size:               0.875rem !default;
$btn-font-size-lg:            0.9375rem !default;
//$btn-font-size-sm:            0.8125rem !default;
$btn-height:                  2.25rem !default; // 36px
$btn-height-lg:               2.5rem !default; // 40px unofficial
$btn-height-sm:               1.75rem !default; // 28px
$btn-height-xs:               1.5rem !default; // 24px
$btn-padding-x:               $spacer !default;
$btn-flat-padding-x:          $spacer-sm !default;
$btn-group-padding-x:         0.75rem !default;
$btn-padding-y:               0 !default;

// Based on https://material-density.glitch.me/

$btn-icon-width:               3rem !default;
$btn-icon-width-sm:            2.25rem !default;
$btn-icon-width-xs:            1.75rem !default;

// Based on https://material.io/components/buttons-floating-action-button/

$btn-float-dropdown-inner-spacer-y: $spacer !default;
$btn-float-elevation-shadow:        map-get($elevation-shadows, 6) !default;
$btn-float-elevation-shadow-hover:  map-get($elevation-shadows, 8) !default;
$btn-float-elevation-shadow-active: map-get($elevation-shadows, 12) !default;
$btn-float-size:                    3.5rem !default; // 56px
$btn-float-size-sm:                 2.5rem !default; // 40px
$btn-float-extended-height:         3rem !default; // 48px
$btn-float-extended-padding-x:      1.25rem !default; // 20px
$btn-float-extended-border-radius:  $btn-float-extended-height / 2 !default; // 24px
$btn-float-extended-line-height:    2.25rem !default;

// Based on https://material.io/components/buttons/#toggle-button

$btn-group-bg:             $white-primary !default;
$btn-group-inner-spacer-x: 1px !default;
$btn-group-inner-spacer-y: 1px !default;
$btn-group-toggle-opacity: 0.7 !default;

// Based on https://material.io/components/cards/

$card-action-inner-spacer-x:  $spacer-sm !default;
$card-action-inner-spacer-y:  $spacer-xs !default;
$card-action-padding-x:       $spacer-sm !default;
$card-action-padding-y:       $card-action-padding-x !default;
$card-bg:                     $white !default;
$card-border-color:           $border-color !default;
$card-border-radius:          $border-radius !default;
$card-border-width:           $border-width !default;
$card-columns-count:          2 !default;
$card-columns-count-desktop:  3 !default;
$card-elevation-shadow:       map-get($elevation-shadows, 1) !default;
$card-elevation-shadow-hover: map-get($elevation-shadows, 4) !default;
$card-inner-border-radius:    subtract($card-border-radius, $card-border-width) !default;
$card-margin-x:               $spacer-sm !default;
$card-margin-y:               $card-margin-x !default;
$card-padding-x:              $spacer !default;
$card-padding-y:              $card-padding-x !default;

// Based on https://material.io/components/chips/

$chip-bg:                     $material-color-grey-300 !default;
$chip-bg-hover:               rgba($black, $overlay-focus-colored-opacity) !default;
$chip-bg-active:              rgba($black, $overlay-pressed-colored-opacity) !default;
$chip-outline-bg-hover:       rgba($black, $overlay-hover-white-opacity) !default;
$chip-outline-bg-active:      rgba($black, $overlay-pressed-white-opacity) !default;
$chip-color:                  $black-primary !default;
$chip-font-size:              0.875rem !default;
$chip-font-weight:            $font-weight-regular !default;
$chip-height:                 2rem !default;
$chip-icon-bg:                theme-color(secondary) !default;
$chip-icon-font-size:         1rem !default;
$chip-icon-size:              1.25rem !default;
$chip-inner-spacer-x:         $spacer-xs !default;
$chip-letter-spacing:         $letter-spacing-body-2 !default;
$chip-line-height:            1.25rem !default;
$chip-padding-x:              0.75rem !default;
$chip-padding-y:              0 !default;

// Based on https://material.io/components/data-tables/

$table-bg:                    $white !default;
$table-bg-active:             rgba(theme-color(primary), $overlay-hover-white-opacity) !default;
//$table-bg-active-hover:       $material-color-grey-300 !default;
$table-bg-accent:             $material-color-grey-100 !default;
$table-bg-hover:              rgba($black, $overlay-hover-white-opacity) !default;
$table-border-color:          $border-color-solid !default;
$table-border-width:          $border-width !default;
$table-cell-padding-x:        $spacer !default;
// $table-cell-padding-x-alt:    $spacer-lg !default;
$table-font-size:             0.875rem !default;
$table-line-height:           $line-height-base !default;
$table-margin-y:              $spacer !default;
$table-striped-order:         odd !default;

$table-dark-bg:               $dark-theme-4 !default;
$table-dark-bg-accent:        $dark-theme-3 !default;
$table-dark-bg-hover:         #565761 !default;
$table-dark-border-color:     #55555d !default;
$table-dark-color:            $white !default;

$table-tbody-cell-height:     3.25rem !default;
//$table-tbody-font-size:       0.8125rem !default;

$table-tfoot-cell-height:     3.5rem !default; // 56px
$table-tfoot-color:           $black-secondary !default;
$table-tfoot-font-size:       0.75rem !default;
$table-tfoot-font-weight:     $font-weight-regular !default;

$table-thead-bg:              $table-bg-accent !default;
$table-thead-cell-height:     $table-tfoot-cell-height !default;
$table-thead-font-weight:     $font-weight-medium !default;
$table-thead-letter-spacing:  0.0071428571em !default;
$table-thead-padding-y:       (($table-thead-cell-height - $table-font-size * $table-line-height) / 2) !default;

$table-th-font-weight:        null !default;

$table-sm-tbody-cell-height:  2.25rem !default;
$table-sm-tfoot-cell-height:  2.5rem !default;
$table-sm-thead-cell-height:  $table-sm-tfoot-cell-height !default;

// Based on https://material.io/components/dialogs/

$dialog-backdrop-bg:                  rgba($black, 0.32) !default;
$dialog-body-padding-y:               1.25rem !default;
$dialog-border-radius:                $border-radius !default;
$dialog-color:                        $black-caption !default;
$dialog-content-bg:                   $white !default;
$dialog-elevation-shadow:             map-get($elevation-shadows, 24) !default;
$dialog-footer-inner-spacer-x:        $spacer-sm !default;
$dialog-footer-padding-x:             $spacer-sm !default;
$dialog-footer-padding-y:             $spacer-sm !default;
$dialog-margin-x:                     $spacer !default;
$dialog-margin-y:                     $spacer !default;
$dialog-padding-x:                    $spacer-lg !default;
$dialog-padding-y:                    $spacer-sm !default;
$dialog-transition-scale:             0.8 !default;
$dialog-width:                        35rem !default; // 560px
$dialog-width-lg:                     52.5rem !default; // 840px
$dialog-width-sm:                     17.5rem !default; // 280px
$dialog-width-xl:                     $dialog-width-lg !default;

// Based on https://material.io/archive/guidelines/components/expansion-panels.html

$expansion-panel-bg:                    $white !default;
$expansion-panel-bg-hover:              rgba($black, $overlay-hover-white-opacity) !default;
$expansion-panel-bg-focus:              rgba($black, $overlay-focus-white-opacity) !default;
$expansion-panel-bg-active:             rgba($black, $overlay-pressed-white-opacity) !default;
$expansion-panel-bg-selected:           rgba(theme-color(primary), $overlay-activated-white-opacity) !default;
$expansion-panel-bg-primary-hover:      rgba(theme-color(primary), $overlay-selected-colored-opacity) !default;
$expansion-panel-bg-primary-focus:      rgba(theme-color(primary), $overlay-pressed-white-opacity) !default;
$expansion-panel-border-color:          $border-color !default;
$expansion-panel-border-radius:         $border-radius !default;
$expansion-panel-border-width:          $border-width !default;
$expansion-panel-color:                 $black-primary !default;
$expansion-panel-color-selected:        theme-color(primary) !default;
$expansion-panel-color-disabled:        $black-hint !default;
//$expansion-panel-elevation-shadow:      map-get($elevation-shadows, 1) !default;
$expansion-panel-font-size:             $font-size-base !default;
$expansion-panel-footer-btn-min-width:  4rem !default;
$expansion-panel-footer-inner-spacer-x: $spacer-sm !default;
$expansion-panel-footer-inner-spacer-y: $spacer-xs !default;
$expansion-panel-footer-padding-x:      $spacer-sm !default;
$expansion-panel-height:                3rem !default;
$expansion-panel-inner-spacer-x:        $spacer !default;
$expansion-panel-inner-spacer-y:        $expansion-panel-inner-spacer-x !default;
$expansion-panel-letter-spacing:        $letter-spacing-subtitle-1 !default;
$expansion-panel-line-height:           $line-height-base !default;
$expansion-panel-margin-y:              $spacer !default;
$expansion-panel-padding-x:             $spacer !default;
$expansion-panel-padding-y:             (($expansion-panel-height - $expansion-panel-font-size * $expansion-panel-line-height) / 2) !default;

$list-group-item-icon-size:     1.5rem !default;
$list-group-item-graphic-width: 2.5rem !default;
$list-group-item-graphic-color: rgba($black, 0.3) !default;

// Material icons
$material-icon-font-family: 'Material Icons', 'Material Icons Outlined', 'Material Icons Two Tone', 'Material Icons Round', 'Material Icons Sharp' !default;

// Based on https://material.io/components/menus/

$menu-bg:                       $white !default;
$menu-border-radius:            $border-radius !default;
$menu-divider-bg:               $border-color !default;
$menu-font-size:                1rem !default;
$menu-font-size-cascading:      0.9375rem !default;
$menu-font-weight:              $font-weight-regular !default;
$menu-elevation-shadow:         map-get($elevation-shadows, 8) !default;
//$menu-header-color:             $black-primary !default;
$menu-line-height:              1.5rem !default;
$menu-line-height-cascading:    $menu-line-height !default;
$menu-link-bg:                  transparent !default;
$menu-link-bg-hover:            rgba($black, $overlay-hover-white-opacity) !default;
$menu-link-bg-focus:            rgba($black, $overlay-focus-white-opacity) !default;
$menu-link-bg-active:           rgba($black, $overlay-pressed-white-opacity) !default;
$menu-link-color:               $black-primary !default;
$menu-link-color-disabled:      $black-hint !default;
$menu-link-height:              3rem !default; // 48px
$menu-link-height-cascading:    2rem !default; // 32px
$menu-link-letter-spacing:      $letter-spacing-subtitle-1 !default;
$menu-link-padding-x:           $spacer !default;
$menu-link-padding-y:           0 !default;
$menu-min-width:                7rem !default; // 112px
$menu-min-width-cascading:      17.5rem !default; // 20 - 320px ?
$menu-padding-y:                $spacer-sm !default;
$menu-padding-y-cascading:      $spacer !default;

// Based on https://material.io/components/navigation-drawer/

$navdrawer-backdrop-bg:             $dialog-backdrop-bg !default;
$navdrawer-border-color:            $border-color !default;
$navdrawer-border-width:            $border-width !default;
$navdrawer-content-bg:              $dialog-content-bg !default;
$navdrawer-divider-bg:              $border-color !default;
$navdrawer-elevation-shadow:        map-get($elevation-shadows, 16) !default;
$navdrawer-gutter-width:            3.5rem !default;
$navdrawer-header-bg:               $material-color-grey-100 !default;
$navdrawer-header-border-color:     $border-color !default;
$navdrawer-header-border-width:     $border-width !default;
$navdrawer-inner-spacer-x:          $spacer !default;
$navdrawer-inner-spacer-y:          $spacer-sm !default;
$navdrawer-nav-icon-color:          $black-secondary !default;
$navdrawer-nav-icon-width:          3.5rem !default;
$navdrawer-nav-link-bg-hover:       $material-color-grey-100 !default;
$navdrawer-nav-link-color:          $black-primary !default;
$navdrawer-nav-link-color-active:   theme-color(primary) !default;
$navdrawer-nav-link-color-disabled: $black-hint !default;
$navdrawer-nav-link-font-size:      0.875rem !default;
$navdrawer-nav-link-font-weight:    $font-weight-medium !default;
$navdrawer-nav-link-height:         2.5rem !default;
$navdrawer-subheader-color:         $black-hint !default;
$navdrawer-subheader-font-size:     0.875rem !default;
$navdrawer-subheader-font-weight:   $font-weight-medium !default;
$navdrawer-subheader-height:        3rem !default;
$navdrawer-width:                   16rem !default;

// Pickers
// Based on https://material.io/components/pickers/

$picker-border-radius:        $border-radius !default;
$picker-cell-size:            2.5rem !default;
$picker-content-bg:           $dialog-content-bg !default;
$picker-day-bg-selected:      theme-color(primary) !default;
$picker-day-color-disabled:   $black-hint !default;
//$picker-day-color-today:      theme-color(primary) !default;
$picker-elevation-shadow:     $dialog-elevation-shadow !default;
$picker-header-bg:            theme-color(primary) !default;
$picker-header-padding-x:     1.5rem !default;
$picker-header-padding-y:     $spacer !default;
$picker-holder-bg:            $dialog-backdrop-bg !default;
$picker-inner-spacer-x:       $spacer-sm !default;
$picker-inner-spacer-y:       $picker-inner-spacer-x !default;
$picker-margin-x:             $dialog-margin-x !default;
$picker-margin-y:             $dialog-margin-y !default;
$picker-nav-next-icon:        'keyboard_arrow_right' !default;
$picker-nav-prev-icon:        'keyboard_arrow_left' !default;
$picker-select-bg:            $black-divider !default;
$picker-select-border-radius: $border-radius !default;
$picker-select-opacity:       0.7 !default;
$picker-transition-scale:     $transition-scale !default;
$picker-weekday-color:        $black-hint !default;

// Based on https://material.io/components/progress-indicators/

$progress-bar-bg:                      theme-color(primary) !default;
$progress-bar-border-radius:           0.25rem !default;
$progress-bar-buffer-width:            1.5rem !default;
$progress-bar-height:                  0.25rem !default; // 4px
$progress-bg:                          #e6e6e6 !default;

$progress-circular-animation-duration: 5.332s !default;
$progress-circular-bg:                 theme-color(primary) !default;
$progress-circular-height:             2.5rem !default;
$progress-circular-height-sm:          1rem !default;
$progress-circular-spinner-width:      0.25rem !default;
$progress-circular-spinner-width-sm:   0.15rem !default;

// Based on https://material.io/components/selection-controls/

//$selection-control-border-radius:               50% !default; // added djibe
$selection-control-checkbox-icon:               'check_box_outline_blank' !default;
$selection-control-checkbox-icon-checked:       'check_box' !default;
$selection-control-checkbox-icon-indeterminate: 'indeterminate_check_box' !default;
$selection-control-color:                       $black-secondary !default;
$selection-control-color-active:                selection-theme-color() !default;
$selection-control-color-disabled:              rgba($black, 0.26) !default;
//$selection-control-gutter:                      .5rem !default; // addded djibe
$selection-control-indicator-size:              $material-icon-size !default;

$selection-control-radial-opacity:              $black-divider-opacity !default;
$selection-control-radio-icon:                  'radio_button_unchecked' !default;
$selection-control-radio-icon-checked:          'radio_button_checked' !default;
$selection-control-spacer-x:                    ($material-icon-size / 2) !default;
$selection-control-spacer-y:                    ($material-icon-size / 2) !default;
$selection-control-thumb-bg:                    $material-color-grey-050 !default;
// $selection-control-thumb-bg-disabled:           $material-color-grey-400 !default;
// $selection-control-thumb-shadow:                0 1px 5px 0 rgba($black, $black-secondary-opacity) !default;
$selection-control-track-bg:                    $black-hint !default;
// $selection-control-track-bg-disabled:           $black-divider !default;
$selection-control-track-height:                1rem !default;
$selection-control-track-opacity:               0.5 !default;
// $selection-control-track-width:                 ($selection-control-indicator-size * 2) !default;
//$selection-control-border-width:                (($selection-control-indicator-size - $selection-control-track-height) / 2); // djibe

//$custom-control-gutter:                         1.75rem !default;
$custom-control-indicator-size:                 1rem !default;
$custom-control-indicator-border-width:         1.25rem !default;
//$custom-control-indicator-border-color:         $material-color-grey-800 !default;
//$custom-control-indicator-bg:                   $white-primary !default;
$custom-switch-handle-width:                    1.25rem !default;
$custom-switch-handle-shadow:                   0 3px 1px -2px rgba($black, 0.2), 0 2px 2px 0 rgba($black, 0.14), 0 1px 5px 0 rgba($black, 0.12) !default;
$custom-switch-height:                          0.875rem !default;
$custom-switch-transition:                      90ms cubic-bezier(0.4, 0, 0.2, 1) !default;
$custom-switch-width:                           2rem !default;
//$custom-switch-indicator-border-radius:         $custom-control-indicator-size / 2 !default;
//$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) !default;
//$custom-control-indicator-checked-disabled-bg:  rgba(theme-color(primary), 0.5) !default;

// Based on https://material.io/components/sliders/
// Using values from https://material.io/develop/android/components/sliders

$slider-accessibility: 3rem !default; // 48px
$slider-track-width: 100% !default;
$slider-track-height: 0.25rem !default; // 0.125rem
$slider-track-height-progress: 0.375rem !default;
$slider-track-cursor: pointer !default;
$slider-track-bg: rgba(selection-theme-color(), $overlay-activated-colored-opacity) !default;
$slider-track-bg-disabled: #d7d7d7 !default;
$slider-track-border-radius: $slider-track-height/2 !default;
$slider-track-progress-border-radius: $slider-track-height-progress/2 !default;
$slider-thumb-width: 1.25rem !default; // 0.75rem
$slider-thumb-height: $slider-thumb-width !default;
$slider-thumb-bg: selection-theme-color() !default;
$slider-thumb-border: 0 !default;
$slider-thumb-border-radius: 50% !default;
$slider-thumb-box-shadow: map-get($shadows, 1) !default;
$slider-thumb-box-shadow-radius: ($slider-accessibility - $slider-thumb-height) / 2 !default;
$slider-thumb-hover-box-shadow: $slider-thumb-box-shadow, 0 0 0 $slider-thumb-box-shadow-radius rgba(selection-theme-color(), $overlay-focus-white-opacity) !default;
$slider-thumb-active-box-shadow: $slider-thumb-box-shadow, 0 0 0 $slider-thumb-box-shadow-radius rgba(selection-theme-color(), $overlay-pressed-white-opacity) !default;
$slider-thumb-active-box-shadow-width: 0.2rem !default;
$slider-thumb-active-bg: $slider-thumb-bg !default;
$slider-thumb-bg-disabled: #8c8c8c !default;
$slider-thumb-transition: box-shadow 100ms ease-out !default;

// Based on https://material.io/components/snackbars/

$snackbar-bg:                     #333333 !default;
$snackbar-border-radius:          $border-radius !default;
$snackbar-btn-color:              theme-color-light(primary) !default;
$snackbar-color:                  rgba($white, $black-primary-opacity) !default;
$snackbar-elevation-shadow:       map-get($elevation-shadows, 6) !default;
$snackbar-letter-spacing:         $letter-spacing-body-2 !default;
$snackbar-line-height:            $line-height-body-2 !default;
$snackbar-margin:                 $spacer-sm !default;
$snackbar-max-width:              42rem !default;
$snackbar-min-width:              21.5rem !default;
$snackbar-padding-x:              $spacer !default;
$snackbar-padding-y:              0.875rem !default;

// Based on https://material.io/archive/guidelines/components/steppers.html

$stepper-bg:                $white !default;
$stepper-border-color:      $black-divider !default;
$stepper-border-width:      $border-width !default;
$stepper-icon-bg:           $black-hint !default;
$stepper-icon-bg-active:    theme-color(primary) !default;
$stepper-icon-color:        $white-primary !default;
$stepper-icon-font-size:    0.75rem !default;
$stepper-icon-icon-size:    1rem !default;
$stepper-icon-height:       1.5rem !default;
$stepper-inner-spacer:      $spacer-sm !default;
$stepper-padding-x:         $spacer-lg !default;
$stepper-padding-y:         $spacer-lg !default;
$stepper-text-color:        $black-hint !default;
$stepper-text-color-active: $black-primary !default;
$stepper-text-font-size:    $font-size-body-2 !default;
$stepper-text-font-weight:  $font-weight-regular !default;

// Based on https://material.io/components/tabs/

$nav-tab-bg-hover:         $black-divider !default;
$nav-tab-color:            $black-secondary !default; //$black-primary !default;
$nav-tab-color-active:     selection-theme-color() !default;
$nav-tab-color-disabled:   $black-hint !default;
$nav-tab-font-size:        $font-size-body-2 !default;
$nav-tab-font-weight:      $font-weight-medium !default;
$nav-tab-height:           3rem !default;
$nav-tab-indicator-bg:     selection-theme-color() !default;
$nav-tab-indicator-height: 0.125rem !default;
$nav-tab-letter-spacing:   0.0892857143em !default;
$nav-tab-line-height:      1 !default;
//$nav-tab-link-opacity:     $white-secondary-opacity !default;
$nav-tab-link-padding-x:   $spacer !default; //.75rem
$nav-tab-link-padding-x-desktop:   $spacer-lg !default;
//$nav-tab-link-padding-y:   (($nav-tab-height - $nav-tab-font-size * $nav-tab-line-height) / 2) !default;

// Based on https://material.io/components/text-fields/

$textfield-grid-gutter-width: 10px !default;

$textfield-border-color:          rgba($black, 0.42) !default;
$textfield-textarea-border-color: rgba($black, 0.24) !default;
$textfield-border-color-hover: $black-primary !default;
$textfield-border-color-focus: selection-theme-color() !default;
$textfield-border-width:       1px !default;
$textfield-border-width-hover: ($textfield-border-width * 2) !default;
$textfield-border-width-focus: $textfield-border-width-hover !default;
$textfield-color:              $black-primary !default;
$textfield-color-disabled:     $black-hint !default;
$textfield-caption-color:      $black-caption !default;
$textfield-hint-color:         $black-hint !default;
$textfield-margin-x:           $spacer !default;
$textfield-placeholder-color:  $black-secondary !default;
$textfield-plaintext-color:    $body-color !default;

$textfield-font-size:          1rem !default;
$textfield-font-size-lg:       2.125rem !default;
$textfield-font-size-sm:       0.8125rem !default;
$textfield-height:             2.25rem !default;
$textfield-height-lg:          3.75rem !default;
$textfield-height-sm:          2rem !default;
$textfield-letter-spacing:     $letter-spacing-subtitle-1 !default;
$textfield-line-height:        1.5 !default; // 1.75rem
$textfield-line-height-lg:     1.176471 !default;
$textfield-line-height-sm:     1.538462 !default;
$textfield-margin-y:           $spacer-sm !default;
$textfield-margin-y-lg:        0.75rem !default;
$textfield-margin-y-sm:        $spacer-xs !default;
$textfield-padding-x:          0 !default;
$textfield-padding-x-lg:       0 !default;
$textfield-padding-x-sm:       0 !default;
$textfield-padding-y:          (($textfield-height - $textfield-font-size * $textfield-line-height) / 2) !default;
$textfield-padding-y-lg:       (($textfield-height-lg - $textfield-font-size-lg * $textfield-line-height-lg) / 2) !default;
$textfield-padding-y-sm:       (($textfield-height-sm - $textfield-font-size-sm * $textfield-line-height-sm) / 2) !default;

$textfield-select-bg-size:     ($material-icon-size / $textfield-font-size * 1em) !default;

// Based on https://material.io/components/text-fields/

$textfield-box-bg:             rgba($black, $overlay-hover-white-opacity) !default;
$textfield-box-bg-hover:       rgba($black, 0.07) !default;
$textfield-box-bg-focus:       rgba($black, 0.14) !default;
$textfield-box-border-radius:  $border-radius !default;

$textfield-box-height:         3.5rem !default; // 56px
$textfield-box-height-lg:      4.25rem !default;
$textfield-box-height-sm:      2.75rem !default;
$textfield-box-label-spacer-y: $spacer-sm !default;
$textfield-box-padding-x:      $spacer !default;
$textfield-box-padding-x-lg:   $textfield-box-padding-x !default;
$textfield-box-padding-x-sm:   0.75rem !default;
$textfield-box-padding-y:      (($textfield-box-height - $textfield-font-size * $textfield-line-height) / 2) !default;
$textfield-box-padding-y-lg:   (($textfield-box-height-lg - $textfield-font-size-lg * $textfield-line-height-lg) / 2) !default;
$textfield-box-padding-y-sm:   (($textfield-box-height-sm - $textfield-font-size-sm * $textfield-line-height-sm) / 2) !default;
$textfield-box-placeholder-color:  $black-caption !default;

// Text field: floating label
// Based on https://material.io/components/text-fields/

$floating-label-color:           $black-secondary !default;
$floating-label-color-box:       $black-caption !default;
$floating-label-color-focus:     rgba(selection-theme-color(), $black-primary-opacity) !default;
$floating-label-font-size:       0.75rem !default;
$floating-label-font-size-lg:    $font-size-body-2 !default;
$floating-label-font-size-sm:    0.625rem !default;

// Based on https://material.io/components/app-bars-top/

$toolbar-color:                 $black-primary !default;
$toolbar-color-lighter:         $black-hint !default;
$toolbar-element-bg:            $black-divider !default;
$toolbar-element-border-radius: $border-radius !default;
$toolbar-element-height:        2.25rem !default;
$toolbar-element-opacity:       0.7 !default;
$toolbar-elevation-shadow:      map-get($elevation-shadows, 4) !default;
$toolbar-height:                3.5rem !default;
$toolbar-inner-spacer-x:        $spacer !default;
$toolbar-link-font-size:        1rem !default;
$toolbar-link-height:           $toolbar-element-height !default;
$toolbar-link-line-height:      1 !default;
$toolbar-link-padding-x:        $spacer !default;
$toolbar-link-padding-y:        (($toolbar-link-height - $toolbar-link-font-size * $toolbar-link-line-height) / 2) !default;
$toolbar-padding-x:             $spacer !default;
$toolbar-padding-y:             (($toolbar-height - $toolbar-element-height) / 2) !default;
$toolbar-toggler-icon:          'menu' !default;
$toolbar-waterfall-bg:          theme-color(primary) !default;

$toolbar-dark-color:            $white-primary !default;
$toolbar-dark-color-lighter:    $white-hint !default;
$toolbar-dark-element-bg:       $white-divider !default;

// Based on https://material.io/components/tooltips/

$tooltip-bg:                #6d6d6d !default;
$tooltip-border-radius:     $border-radius !default;
$tooltip-breakpoint:        lg !default;
$tooltip-color:             $white-primary !default;
$tooltip-font-size:         $font-size-body-2 !default;
$tooltip-font-size-desktop: 0.625rem !default;
$tooltip-font-weight:       $font-weight-medium !default;
$tooltip-height:            2rem !default; // 32px
$tooltip-height-desktop:    1.5rem !default; // 24px
$tooltip-line-height:       $line-height-base !default;
$tooltip-margin:            $spacer-lg !default; // 24px
$tooltip-margin-desktop:    0.875rem !default;    // 14px
$toolbar-nav-scroll-max-height: 75vh !default;
$tooltip-opacity:           0.9 !default;
$tooltip-padding-x:         $spacer !default;  // 16px
$tooltip-padding-x-desktop: $spacer-sm !default;  // 8px
$tooltip-padding-y:         0 !default;
$tooltip-padding-y-desktop: $tooltip-padding-y !default; // 6px
$tooltip-scale:             0.87 !default;
$tooltip-zindex:            map-get($elevations, 24) !default;
