
// Headings - bootstrap classes
// Keep compatibility for Material UI v1
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $headings-color;
  font-family: $headings-font-family;
  margin-bottom: $headings-margin-y;
}

h1,
.h1,
.display-1,
.typography-display-4,
.typography-headline-1 { @include typography-headline-1; }

h2,
.h2,
.display-2,
.typography-display-3,
.typography-headline-2 { @include typography-headline-2; }

h3,
.h3,
.display-3,
.typography-display-2,
.typography-headline-3 { @include typography-headline-3; }

h4,
.h4,
.display-4,
.typography-display-1,
.typography-headline-4 { @include typography-headline-4; }

h5,
.h5,
.typography-headline,
.typography-headline-5 { @include typography-headline-5; }

h6,
.h6,
.lead,
.typography-headline-6,
.typography-title { @include typography-headline-6; }

.typography-subheading,
.typography-subtitle-1 {
  @include typography-subtitle-1;
}

.typography-subtitle-2 {
  @include typography-subtitle-2;
}

.typography-body-1 {
  @include typography-body-1;
}

.typography-body-2 {
  @include typography-body-2;
}

.typography-caption {
  @include typography-caption;
}

.typography-overline {
  @include typography-overline;
}

p { font-size: 1rem; } // djibe addition

//
// Horizontal rules
//

hr {
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
  margin-top: $paragraph-margin-y;
  margin-bottom: $paragraph-margin-y;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-regular;
}

mark,
.mark {
  background-color: $mark-bg;
  color: $mark-color;
  padding: $mark-padding;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-inner-spacer-x;
  }
}

//
// Misc
//

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  @include typography-body-1;

  border-left: $blockquote-border-width solid $blockquote-border-color;
  margin-bottom: $paragraph-margin-y;
  padding: 0 $spacer;
}

.blockquote-footer {
  @include typography-caption;

  color: $blockquote-small-color;
  display: block;
  margin-top: $spacer-xs;

  &::before {
    content: '\2014 \00A0'; // — &nbsp;
  }
}
