.nav-tabs {
  flex-wrap: nowrap;
  width: 100%; // addition

  &.nav-inverse .nav-link {
    color: rgba($white, $white-hint-opacity);

    &.active {
      color: $white;
    }

    &:hover {
      background-color: rgba($white, $overlay-hover-colored-opacity);
    }

    &:focus {
      background-color: rgba($white, $overlay-focus-colored-opacity);
    }

    &:active {
      background-color: rgba($white, $overlay-pressed-colored-opacity);
    }

    &::before {
      background-color: $white;
    }
  }

  .nav-link {
    @include transition-standard(background-color, color, opacity);
    @include text-truncate;

    align-items: center;
    color: $nav-tab-color;
    display: flex;
    flex: 1 0 auto;
    font-size: $nav-tab-font-size;
    font-weight: $nav-tab-font-weight;
    height: $nav-tab-height;
    justify-content: center;
    letter-spacing: $nav-tab-letter-spacing;
    line-height: 2.25rem;
    // margin-bottom: -$nav-tabs-border-width;
    max-width: 22.5rem;
    padding: 0 $nav-tab-link-padding-x;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
      & { padding: 0 $nav-tab-link-padding-x-desktop; }
    }

    &::before {
      @include transition-standard(opacity);

      background-color: $nav-tab-indicator-bg;
      content: '';
      display: block;
      height: $nav-tab-indicator-height;
      opacity: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      background-color: rgba(selection-theme-color(), $overlay-hover-white-opacity);
    }

    &:focus {
      background-color: rgba(selection-theme-color(), $overlay-focus-white-opacity);
    }

    &:active {
      background-color: rgba(selection-theme-color(), $overlay-pressed-white-opacity);
      color: selection-theme-color(); // testing ripple
      opacity: 1;
    }

    // stylelint-disable
    &.flex-column {
      height: 4.5rem;

      i,
      [class*='material-icons'] {
        margin: .75rem 0 -3px;
      }
    }

    &.active {
      color: $nav-tab-color-active;

      &::before {
        opacity: 1;
      }
    }
    // stylelint-enable

    &.disabled {
      background-color: transparent;
      color: $nav-tab-color-disabled;
      opacity: 1;
    }

    i,
    [class*='material-icons'] {
      margin-right: $spacer-sm;
    }
  }

  .nav-item.show .nav-link {
    background-color: $nav-tab-bg-hover;
    //opacity: 1;
  }
}

// Animated bottom bar
.nav-tabs-material {
  position: relative;

  &.animate {
    .nav-link::before {
      opacity: 0;
    }

    .nav-tabs-indicator {
      @include transition-standard(left, right);
    }
  }

  &.nav-inverse .nav-tabs-indicator {
    background-color: $white;
  }

  .nav-link {
    min-width: 5.625rem;

    &::before {
      transition: none;
    }
  }

  .nav-tabs-indicator {
    background-color: $nav-tab-indicator-bg;
    display: none;
    height: $nav-tab-indicator-height;
    position: absolute;
    bottom: 0;

    &.show {
      display: block;
    }

    .nav-tabs-scrollable & {
      bottom: $nav-tab-height;
    }
  }
}

.nav-tabs-scrollable {
  .nav-tabs {
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 2.5rem;
  }
}
