// TODO: doc

// Clear icon
::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none; // stylelint-disable-line
  background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg fill="#{$black}" fill-opacity="#{$black-secondary-opacity}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'));
  color: $black-secondary;
  cursor: pointer;
  height: 1.2em;
  margin: 0 0 0 1.5rem;
  padding: 2px 3px;
  width: 1.2em;
}

// Calendar icon
::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.6;
}

// Transparency for autocompleted inputs

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: $textfield-color;
}
