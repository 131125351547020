// Todo ellipsis tooltip
.tooltip {
  display: block;
  font-size: $tooltip-font-size;
  font-weight: $tooltip-font-weight;
  line-height: $tooltip-line-height;
  margin: $tooltip-margin;
  max-width: 250px;
  opacity: 0;
  position: absolute;
  word-break: break-word;
  z-index: $tooltip-zindex;

  @include media-breakpoint-up($tooltip-breakpoint) {
    font-size: $tooltip-font-size-desktop;
    margin: $tooltip-margin-desktop;
  }

  &.show {
    opacity: $tooltip-opacity;

    .tooltip-inner {
      @include transition-deceleration(transform);

      transform: scale(1);
    }
  }
}

.tooltip-inner {
  @include border-radius($tooltip-border-radius);

  align-items: center;
  background-color: $tooltip-bg;
  color: $tooltip-color;
  display: flex;
  height: $tooltip-height; // 24px
  margin: auto;
  max-width: 250px - (2*8px);
  overflow: hidden;
  padding: $tooltip-padding-y $tooltip-padding-x;
  text-overflow: clip;
  transform: scale($tooltip-scale);
  transform-origin: center top;
  transition: transform 75ms $transition-timing-function-acceleration;
  white-space: nowrap;

  @include media-breakpoint-up($tooltip-breakpoint) {
    height: $tooltip-height-desktop;
    padding: $tooltip-padding-y-desktop $tooltip-padding-x-desktop;
  }
}
